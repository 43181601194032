/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
// import { Image } from 'react-bootstrap/Image'

// reactstrap components

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.js'
import IndexHeader from 'components/Headers/IndexHeader.js'
import DemoFooter from 'components/Footers/DemoFooter.js'

import Styles from '../utils/Styles'

import ZGCarousel from 'components/ZGCarousel'

const SPONSORS = [
  Styles.images.partners.freshDiversion,
  Styles.images.partners.kacs,
  Styles.images.partners.kiafa,
  Styles.images.partners.perrier,
  Styles.images.partners.usEmbassy,
]

function Index() {
  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('index')
    return function cleanup() {
      document.body.classList.remove('index')
    }
  })
  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      {/* <br /> */}
      <Container
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: 50,
        }}
      >
        <div className='filter' />
        <Row style={{ marginTop: 25 }}>
          <Col>
            <ZGCarousel />
          </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
          <Col xs={12} m={6} lg={6}>
            <video width='90%' height='240' controls>
              <source src={Styles.videos.facebookVid} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
            {/* <div
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ margin: 10 }}>
                
              </span>
              <span style={{ margin: 10 }}> */}
            {/* <video width='45%' height='240' controls>
                  <source src={Styles.videos.exhibitionVid} type='video/mp4' />
                  Your browser does not support the video tag.
                </video> */}
            {/* </span>
            </div> */}
          </Col>
          <Col xs={12} m={6} lg={6}>
            <video width='90%' height='240' controls>
              <source src={Styles.videos.exhibitionVid} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row>
      </Container>
      <DemoFooter />
    </>
  )
}

export default Index
