import * as React from 'react'
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap'
import Styles from 'utils/Styles'

{
  /* <h1>ZamyGo</h1>
<h3>Media Distribution Company</h3> */
}

// 'ZamyGo | Media Distribution Company'

const items = [
  {
    src: Styles.images.carouselItems.slides[0],
    altText: 'Media Distribution',
    captionHeader: 'ZamyGo | Media Distribution Company',
    captionText: `ZamyGo is a new media distribution company with the mission to
              Inspire, Connect, and Impact through the power of Storytelling. We
              have partnered with Tonko House, an Oscar-nominated,
              Eisner-nominated, and Annecy Cristal award winning studio to
              launch an exhibition of their characters and stories in Korea.`,
  },
  {
    src: Styles.images.carouselItems.slides[1],
    altText: 'ZamyGo | Media Distribution Company',
    captionHeader: 'ZamyGo | Media Distribution Company',
    captionText: `ZamyGo is a new media distribution company with the mission to
              Inspire, Connect, and Impact through the power of Storytelling. We
              have partnered with Tonko House, an Oscar-nominated,
              Eisner-nominated, and Annecy Cristal award winning studio to
              launch an exhibition of their characters and stories in Korea.`,
  },
  {
    src: Styles.images.carouselItems.slides[2],
    altText: 'ZamyGo | Media Distribution Company',
    captionHeader: 'ZamyGo | Media Distribution Company',
    captionText: `ZamyGo is a new media distribution company with the mission to
              Inspire, Connect, and Impact through the power of Storytelling. We
              have partnered with Tonko House, an Oscar-nominated,
              Eisner-nominated, and Annecy Cristal award winning studio to
              launch an exhibition of their characters and stories in Korea.`,
  },
]

const { useState, useEffect } = React
export default function ZGCarousel(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map((item, idx) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={idx}
      >
        <div
          style={{
            borderRadius: 5,
            height: 500,
            background: 'url(' + item.src + ')',
            backgroundPosition: 'center',
          }}
        >
          {/* <img
            src={item.src}
            alt={item.altText}
            height={'auto'}
            width={'100%'}
          /> */}
        </div>
        {/* <CarouselCaption
          style={{
            color: 'black',
          }}
          captionText={item.captionText}
          captionHeader={item.captionHeader}
        /> */}
      </CarouselItem>
    )
  })

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction='prev'
        directionText='Previous'
        onClickHandler={previous}
      />
      <CarouselControl
        direction='next'
        directionText='Next'
        onClickHandler={next}
      />
    </Carousel>
  )
}
