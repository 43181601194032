const colors = {}
const fonts = {}
const images = {
  carouselItems: {
    slides: [
      require('../assets/img/DK_Sculpture.png'),
      require('../assets/img/DK_Poems.png'),
      require('../assets/img/DK_Short.png'),
    ],
  },
  tonkoMembers: {
    // dice: require('../assets/img/TKH/members/tonko_dice.jpeg'),
    dice: require('../assets/img/Dice.JPG'),
    robert: require('../assets/img/TKH/members/tonko_robert.jpeg'),
    eric: require('../assets/img/TKH/members/tonko_eric.jpeg'),
    JJ: require('../assets/img/TKH/members/tonko_JJ.JPG'),
  },
  partners: {
    freshDiversion: require('../assets/img/zamygo_partner_fresh-diversion.png'),
    kacs: require('../assets/img/zamygo_partner_kaces.png'),
    kiafa: require('../assets/img/zamygo_partner_kiafa.png'),
    perrier: require('../assets/img/zamygo_partner_perrier.png'),
    usEmbassy: require('../assets/img/zamygo_partner_US embassy Korean.jpg'),
    // usEmbassy: require('../assets/img/zamygo_partner_us_embassy.png'),
    // usEmbassy: require('../assets/img/zamygo_partner_US_Embassy_Seal.png'),
    siii: require('../assets/img/zamygo_partner_siii.png'),
  },
  events: {
    childrensDay: require('../assets/img/childrensday.png'),
    educationPage: require('../assets/img/educationPage.jpg'),
    chueseokPic: require('../assets/img/chuseokGoogleMike.jpg'),
  },
  smLogos: {
    // insta: require('../assets/img/sm/inst.png'),
    insta: require('../assets/img/sm/instagram-small-icon-12.jpg'),
    fb: require('../assets/img/sm/fb.png'),
    // yt: require('../assets/img/sm/yt.png'),
    yt: require('../assets/img/sm/youtube-logo-2431.png'),
  },
}

const videos = {
  facebookVid: require('../assets/videos/FACEBOOK_Korea Exhibition PR Video_Dice and Robert- Korean Subtitle.mp4'),
  exhibitionVid: require('../assets/videos/Korea Exhibition_Robert, Erick_Final Version_H264. Stereo.mp4'),
}

export default {
  colors,
  fonts,
  images,
  videos,
}
