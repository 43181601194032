/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'

// reactstrap components
import { Row, Container } from 'reactstrap'
import { Link } from 'react-router-dom'
import Styles from '../../utils/Styles'

function DemoFooter() {
  return (
    <footer className='footer footer-black' style={{ borderWidth: 1 }}>
      <Container>
        <Row>
          <nav className='footer-nav'>
            <ul>
              <li>
                <Link to='/index'>
                  {/* <a
                  // href="/index"
                  // target="_blank"
                  > */}
                  {/* ZamyGo */}
                  <img
                    alt='...'
                    className='img-no-padding img-responsive'
                    style={{ width: '100px' }}
                    src={require('assets/img/zamygologo.png')}
                  />
                  {/* </a> */}
                </Link>
              </li>
              {/* <li>
                <Link to='/about'>About</Link>
              </li> */}
              <li>
                <Link to='/events'>Events</Link>
              </li>
              <li>
                <Link to='/press'>Press</Link>
              </li>
            </ul>
          </nav>
          <div className='credits ml-auto'>
            <span
              style={{
                marginRight: 10,
                marginLeft: 10,
                backgroundColor: 'white',
              }}
            >
              <a target='_blank' href='https://www.instagram.com/zamygokorea'>
                <img
                  src={Styles.images.smLogos.insta}
                  height={30}
                  width={30}
                  style={{ backgroundColor: 'white' }}
                />
              </a>
            </span>
            <span style={{ marginRight: 10, marginLeft: 10 }}>
              <a
                target='_blank'
                href='https://www.youtube.com/channel/UCiHU77REijmk_oGT9lQvOOA'
              >
                <img
                  src={Styles.images.smLogos.yt}
                  height={30}
                  width={30}
                  style={{ backgroundColor: 'white' }}
                />
              </a>
            </span>
            <span style={{ marginRight: 10, marginLeft: 10 }}>
              <a target='_blank' href='https://www.facebook.com/zamygo'>
                <img src={Styles.images.smLogos.fb} height={40} width={40} />
              </a>
            </span>
            <span className='copyright'>
              © {new Date().getFullYear()} ZamyGo
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  )
}
// 선릉로 818 서울특별시 강남구 청담동
export default DemoFooter
// https://www.facebook.com/zamygo
// https://www.instagram.com/zamygokorea
