import React from 'react'
import TonkoHouseExhibitionHeader from 'components/Headers/TonkoHouseExhibitionHeader'
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardGroup,
  CardSubtitle,
  CardBody,
  CardHeader,
  Jumbotron,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import IndexNavbar from 'components/Navbars/IndexNavbar.js'
import DemoFooter from 'components/Footers/DemoFooter.js'
import Styles from '../../utils/Styles'
import SponsorsSections from 'components/SponsorsSections'

const TONKO_PROFILES = [
  {
    name: 'Daisuke ‘Dice’ Tsutsumi',
    position: 'Co-Founder',
    img: Styles.images.tonkoMembers.dice,
    bio: `Born and raised in Tokyo, Japan, Dice moved to New York where he enrolled at the School of Visual Arts. Dice was a visual development/color key artist for Blue Sky Studios (“Ice Age,” “Robots,” “Horton Hears A Who”). Joining Pixar in 2007, Dice has worked on the studio’s Academy Award winning films “WALL•E,” “Toy Story 3,” and “Monsters University”. Initially lured to animation from academic oil painting field, Tsutsumi’s love of films developed through his relationships with talented coworkers and he pursues his passion towards making a difference through art. He spearheaded both The Totoro Forest Project and Sketchtravel. He left Pixar and co-founded Tonko House with Robert Kondo to continue his journey to keep learning as an artist and to make a difference through art. Dice is married to Mei Okuyamai, the niece of acclaimed anime director Hayao Miyazaki and the inspiration for Miyazaki’s My Neighbor Totoro’s Mei character.`,
    films: [
      'Ice Age (2002) (matte painting/color stylist art)',
      'Robots (2005) (lead color key artist/additional design)',
      'Horton Hears a Who (2008) (lead color design)',
      'Toy Story 3 (2010) (color and lighting art director)',
      'Cars 2 (2011) (voice role: sushi chef)',
      'Cars 2: The Video Game (2011) (voice role: sushi chef)',
      'Monsters University (2013) (lighting design and character shading art director)',
      'The Dam Keeper (2014) (co-director)',
    ],
  },
  {
    name: 'Robert Kondo',
    position: 'Co-Founder',
    img: Styles.images.tonkoMembers.robert,
    bio: `Born and raised in southern California, Robert Kondo graduated from the Art Center College of Design in Pasadena, where he studied illustration and design with Norm Schureman. Robert joined Pixar Animation Studios in July 2002, and worked as a sets art director on several of the studio’s feature films including Academy Award and Golden Globe Award winning “Ratatouille,” “Monsters University,” "Wall-E," and "Toy Story 3." Some of Kondo’s earlier influences that led him to the animation field include Disney animated films and Hayao Miyazaki’s films. He loves the visual solutions that artists offered in telling stories from around the world. In 2015, Kondo joined Pixar alumni Daisuke “Dice” Tsutsumi to start Tonko House, and create the Academy Award nominated short “The Dam Keeper.”`,
    films: [
      'WALL·E (2008) (art director)',
      'Toy Story 3 (2010) (set art director)',
      'Monsters University (2013) (art director: set design and shading) ',
      'The Dam Keeper (2014) (co-director, producer)',
      'Pig: The Dam Keeper Poems (2017) (executive producer) ',
      'Coco (2017) (visual development artist) ',
      'Ratatouille (2017) (set art director) ',
    ],
  },
  {
    name: 'Erick Oh',
    position: 'Director',
    img: Styles.images.tonkoMembers.eric,
    bio: `Erick Oh is an artist of Korean origin, living and working in California. His independent films have been introduced and nominated at Student Academy Awards, Annecy Animation Festival, Hiroshima Animation Festival, Zagreb Animation Festival, Siggraph, Anima Mundi, Ars Electronica, Angelus Film Festival, Cleveland Film Festival and numerous other film festivals. After receiving his BFA from Fine art Department at Seoul National University, Erick finished his study at UCLA's MFA film program`,
    films: [
      'Cars 2 (2011) (fix animator)',
      'Brave (2012) (fix and additional animator)',
      'Monsters University (2013) (animator)',
      'The Dam Keeper (2014) (supervising animator)',
      'Inside Out (2015) (animator)',
      'Finding Dory (2016 (animator)',
      'Pig: The Dam Keeper Poems (2017) (director',
    ],
  },
  {
    name: 'Jaehee “JJ” Song',
    position: 'Character Design',
    img: Styles.images.tonkoMembers.JJ,
    bio: `Jaehee “JJ” Song is a concept and visual development artist for feature and TV animation. She graduated with a BFA in Industrial and Product Design from Kookmin University, South Korea, and MFA in Visual Development at the Academy of Art University, San Francisco. At present, she is an artist at Tonko House, while also working on her personal animated short film, “Wishing Star”.  JJ loves character design. She puts her heart and soul into designing concepts for animation. Her passion in art makes her tick. Besides studio work, she also enjoys plein air sketching, and leisure sports such as snowboarding things that help her stay healthy and positive in life.`,
    films: [
      'Academy of Art University (2016) (Spring Show, cinematic moment of animation 1st place)',
      'Academy of Art University (2016) (Spring Show, character design 1st place)',
      'Sonder – short animated VR film (2016) (concept art, visual development works)',
      'Pig: The Dam Keeper Poems (2017) (sole artist)',
      'The Dam Keeper, World without Darkness – graphic novel (2017) (art)',
    ],
  },
]

const TonkoHouse = () => {
  const [activeTab, setActiveTab] = React.useState('1')
  const [modal, setModal] = React.useState(false)
  const [films, setFilms] = React.useState([])

  const toggleModal = (profileFilms) => {
    if (profileFilms) setFilms(profileFilms)
    else setFilms([])
    setModal(!modal)
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('index')
    return function cleanup() {
      document.body.classList.remove('index')
    }
  })
  return (
    <>
      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>Filmography</ModalHeader>
        <ModalBody>
          <ListGroup>
            {/* <ListGroupItem>Cras justo odio</ListGroupItem> */}
            {films.map((film) => (
              <ListGroupItem>{film}</ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        {/* <ModalFooter>
          <Button color='primary' onClick={() => toggleModal(null)}>
            Do Something
          </Button>{' '}
          <Button color='secondary' onClick={() => toggleModal(null)}>
            Cancel
          </Button>
        </ModalFooter> */}
      </Modal>
      <IndexNavbar />
      <TonkoHouseExhibitionHeader />
      <Row>
        <Col>
          <Jumbotron>
            <h2 className='display-6'>TONKO HOUSE SEOUL EXHIBITION</h2>
            <p className='lead'>
              Cheongdam-dong, 2019 Collaboration Workshop series
            </p>
            <hr className='my-2' />
            {/* <h5 className="display-6">ABOUT TONKO HOUSE</h5>
            <p>
              Tonko House is an animation studio based in Berkeley, California,
              founded by two former Pixar art directors Robert Kondo and Dice
              Tsutsumi. Tonko House projects span an array of media platforms
              such as films, books, games and educational materials with an
              objective “… to create stories that deliver entertainment and
              awareness for people of all ages.”
            </p> */}
            {/* <p className="lead">
              <Button color="primary">Learn More</Button>
            </p> */}
          </Jumbotron>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <h2 className='display-6'>ABOUT TONKO HOUSE</h2>
            <p className='lead'>
              Tonko House is an animation studio based in Berkeley, California,
              founded by two former Pixar art directors Robert Kondo and Dice
              Tsutsumi. Tonko House projects span an array of media platforms
              such as films, books, games and educational materials with an
              objective “… to create stories that deliver entertainment and
              awareness for people of all ages.”
            </p>
            {/* <Card>
              <CardBody>
                <CardTitle>TONKO HOUSE SEOUL EXHIBITION</CardTitle>
                <CardSubtitle>ABOUT TONKO HOUSE</CardSubtitle>
                <CardText>
                  
                </CardText>
                <CardSubtitle>KEY AWARDS & RECOGNITION</CardSubtitle>
                <CardText>blah blah</CardText>
              </CardBody>
            </Card> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className='display-6'>KEY AWARDS & RECOGNITION</h5>
            <CardGroup>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>The Dam Keeper (animated short)</b>
                  </CardTitle>
                  <ListGroup>
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        2015 Oscar nominee, Best Short Film Animated
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        2015 Annie Award nominee, Best Animated Short Subject
                      </ListGroupItemHeading>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
                <CardImg
                  top
                  width='100%'
                  src={require('../../assets/img/TKH/thedamkeeper.jpg')}
                  alt='Card image cap'
                />
              </Card>
            </CardGroup>
            <CardGroup>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Pig: The Dam Keeper Poems (TV Short Series)</b>
                  </CardTitle>
                  <ListGroup>
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        2018 Annecy Cristal Award winner, TV Production
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        Distributed on NHK and Hulu Japan
                      </ListGroupItemHeading>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
                <Row>
                  <Col>
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/pigseries1.jpg')}
                      alt='Card image cap'
                    />
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/pigseries3.jpg')}
                      alt='Card image cap'
                    />
                  </Col>
                  <Col>
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/pigseries2.jpg')}
                      alt='Card image cap'
                    />
                  </Col>
                </Row>
              </Card>
            </CardGroup>
            <CardGroup>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>The Dam Keeper (graphic novel - trilogy)</b>
                  </CardTitle>
                  <ListGroup>
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        2018 Eisner award nominee, Best Publication for Teens
                      </ListGroupItemHeading>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
                <Row>
                  <Col>
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/thedamkeeper1.jpg')}
                      alt='Card image cap'
                    />
                  </Col>
                  <Col>
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/thedamkeeper2.jpg')}
                      alt='Card image cap'
                    />
                  </Col>
                  <Col>
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/thedamkeeper3.jpg')}
                      alt='Card image cap'
                    />
                  </Col>
                  <Col>
                    <CardImg
                      top
                      width='100%'
                      src={require('../../assets/img/TKH/thedamkeeper4.png')}
                      alt='Card image cap'
                    />
                  </Col>
                </Row>
              </Card>
            </CardGroup>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <h5 className='display-6'>TONKO HOUSE KEY MEMBERS</h5>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <CardGroup>
              <Card>
                <Row>
                  {TONKO_PROFILES.map((profile) => {
                    const { name, position, bio, img, films } = profile
                    return (
                      <Col>
                        <div>
                          <Card flex={1}>
                            <CardImg
                              top
                              width='100%'
                              src={img}
                              alt='Card image cap'
                            />
                            <CardBody>
                              <CardTitle tag='h5'>{name}</CardTitle>
                              <CardSubtitle
                                tag='h6'
                                className='mb-2 text-muted'
                              >
                                {position}
                              </CardSubtitle>
                              <CardText>{bio}</CardText>
                              <Button onClick={() => toggleModal(films)}>
                                Filmography
                              </Button>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </Card>
            </CardGroup>
          </Col>
        </Row>
        <SponsorsSections />
      </Container>
      <DemoFooter />
    </>
  )
}

export default TonkoHouse
