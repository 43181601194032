/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'
// import "node_modules/video-react/dist/video-react.css";
import { Player } from 'video-react'

// reactstrap components
import { Container, Button, Row, Col } from 'reactstrap'

import ZGCarousel from 'components/ZGCarousel'
import Styles from 'utils/Styles'

// core components

function IndexHeader() {
  let pageHeader = React.createRef()

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3
        pageHeader.current.style.transform =
          'translate3d(0,' + windowScrollTop + 'px,0)'
      }
      window.addEventListener('scroll', updateScroll)
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll)
      }
    }
  })

  return (
    <>
      <div
        style={{
          backgroundImage: 'url(' + require('assets/img/DK_Feature.png') + ')',
        }}
        className='page-header page-header-xs'
        data-parallax={true}
        ref={pageHeader}
      >
        <div
          style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
        >
          {/* <div style={{ width: '100%' }}>
            <h1
              style={{ color: '#51bcda', fontWeight: 500, textAlign: 'center' }}
            >
              ZAMYGO
            </h1>
          </div> */}

          <div style={{ padding: 100 }}>
            <p
              style={{
                color: 'white',
                fontWeight: 500,
                fontSize: 20,
                textAlign: 'center',
              }}
            >
              ZamyGo is a new media distribution company with the mission to
              Inspire, Connect, and Impact through the power of Storytelling. We
              have partnered with Tonko House, an Oscar-nominated,
              Eisner-nominated, and Annecy Cristal award winning studio to
              launch an exhibition of their characters and stories in Korea.
            </p>
          </div>
        </div>
        <div className='filter' />
      </div>
    </>
  )
}

export default IndexHeader

{
  /* <div
  style={{
    backgroundImage: "url(" + require("assets/img/DK_Feature.png") + ")",
  }}
  className="page-header"
  data-parallax={true}
  ref={pageHeader}
>
  <div className="filter" />
  <Container>
    <div className="motto text-center">
      <h1>ZamyGo</h1>
      <h3>Media Distribution Company</h3>
      <p>
        ZamyGo is a new media distribution company with the mission to
        Inspire, Connect, and Impact through the power of Storytelling. We
        have partnered with Tonko House, an Oscar-nominated,
        Eisner-nominated, and Annecy Cristal award winning studio to
        launch an exhibition of their characters and stories in Korea.
      </p>
      <br />

    </div>
  </Container>
</div> */
}
