import React from 'react'

import IndexNavbar from 'components/Navbars/IndexNavbar.js'
import DemoFooter from 'components/Footers/DemoFooter.js'
import EventsPageHeader from 'components/Headers/EventsPageHeader'
import { Link } from 'react-router-dom'

import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardHeader,
  // CardActions,
} from 'reactstrap'
// import Index from './Index'
// import SponsorsSections from 'components/SponsorsSections'
// import CardFooter from 'reactstrap/lib/CardFooter'

import Styles from '../utils/Styles'

const EVENTS = [
  {
    cardHeader: 'Tonko House',
    cardTitle: 'Tonko House Animation Exhibition in Seoul',
    imgSrc: require('../assets/img/Tonko_House_Logo.png'),
    descText: `Created by former Pixar artists and art directors, Tonko House
          Animation Exhibition in Seoul 2019 will open its first exhibition
          from May 3, 2019 to Aug. 31, 2019 at a special venue located in
          Chungdam-dong. Located in a 1,322 m2 venue, this exhibition will
          display close to 140 art works of character sketches,
          illustration, videos, etc. and feature a special art program
          created by Tonko House. Visitors can also download an AR app to
          experience moving 3-dimensional characters throughout the
          exhibition as well.`,
    routeTo: '/tonkohouse',
  },
  {
    cardHeader: 'Children’s Day',
    cardTitle: 'Children’s Day Music and Art Performance',
    imgSrc: Styles.images.events.childrensDay,
    descText: `We have partnered with amazing people and organizations to help
              create workshops, panels and events that we hope will inspire many
              of you to take on a journey of reflection and curiosity about
              people and the world.`,
    routeTo: '/childrensday',
  },
  {
    cardHeader: 'KACES Art education workshop event',
    cardTitle: 'UNESCO International Art Education Week 2019 – Art Workshop',
    imgSrc: Styles.images.events.chueseokPic,
    descText: `New art education workshop led by Mike Dutton, Tonko House
              Production Designer and former Google Doodler, famous for
              illustrating the Google – Chuseok homepage`,
    routeTo: '/kacesartworkshop',
  },
]

const EventsPage = () => {
  const [activeTab, setActiveTab] = React.useState('1')

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  document.documentElement.classList.remove('nav-open')
  React.useEffect(() => {
    document.body.classList.add('index')
    return function cleanup() {
      document.body.classList.remove('index')
    }
  })
  // xs: columnProps,
  // sm: columnProps,
  // md: columnProps,
  // lg: columnProps,
  // xl: columnProps,
  // function truncate(str, n) {
  //   return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str
  // }
  // const truncate = (input) =>
  //   input.length > 5 ? `${input.substring(0, 5)}...` : input

  const EventCard = ({ cardHeader, cardTitle, imgSrc, descText, routeTo }) => {
    const truncate = (input) => {
      console.log(input)
      return input ? input.substring(0, 100) + '...' : input
    }

    // input.length > 5 ? `${input.substring(0, 5)}...` : input
    return (
      <Col xs='12' sm='12' md='6'>
        <Card style={{}}>
          <CardHeader>{cardHeader}</CardHeader>
          <CardBody>
            <CardTitle>{cardTitle}</CardTitle>
            <br />
            <img
              src={imgSrc}
              alt='tonkohouselogo'
              width='100px;'
              height='auto'
              // style={{ marginBottom: 25 }}
            />
            <Link to={routeTo}>
              <Button
                style={{
                  marginBottom: 30,
                  marginLeft: 50,
                }}
                // href="/tonkohouse"
                color='primary'
              >
                Read More
              </Button>
            </Link>
            <br />
            <br />
            <CardText>{descText.split('**')[0]}</CardText>
            <CardText>{descText.split('**')[1]}</CardText>
          </CardBody>
          {/* <CardBody
            style={{
              padding: 10,
              // alignItems: 'center',
              // justifyContent: 'center',
            }}
          >
            <Link to={routeTo}>
              <Button
                style={{
                  marginBottom: 55,
                }}
                // href="/tonkohouse"
                color='primary'
              >
                Read More
              </Button>
            </Link>
          </CardBody> */}
        </Card>
      </Col>
    )
  }

  return (
    <>
      <IndexNavbar />
      <EventsPageHeader />
      <Container>
        <br></br>
        {/* <Row>
          <Col>
            <Card>
              <CardHeader>Events</CardHeader>
            </Card>
          </Col>
        </Row> */}
        <Row>
          {EVENTS.map(
            ({ cardHeader, cardTitle, imgSrc, descText, routeTo }) => (
              <EventCard
                cardHeader={cardHeader}
                cardTitle={cardTitle}
                imgSrc={imgSrc}
                descText={descText}
                routeTo={routeTo}
              />
            )
          )}
        </Row>
        <DemoFooter />
      </Container>
    </>
  )
}

export default EventsPage
