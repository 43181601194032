import * as React from 'react'
import {
  Row,
  Col,
  Container,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardImgOverlay,
} from 'reactstrap'
import Styles from '../utils/Styles'

const CardImageOverlay = (props) => {
  return (
    <div
      style={{
        // height: 300,
        width: 150,
        padding: 10,
      }}
    >
      <img src={props.img} height='auto' width={150} alt='partner-logo' />
      {/* <Card inverse>
        <CardImg
          style={{ backgroundColor: 'white' }}
          width='200'
          height='auto'
          src={props.img}
          alt='Card image cap'
        />
      </Card> */}
    </div>
  )
}

const SPONSORS = [
  Styles.images.partners.freshDiversion,
  Styles.images.partners.kacs,
  Styles.images.partners.kiafa,
  Styles.images.partners.perrier,
  Styles.images.partners.usEmbassy,
  Styles.images.partners.siii,
]

export default function SponsorsSections(props) {
  return (
    <Container
      style={{
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ position: 'relative', zIndex: 99999, top: 7 }}>
        <h3 style={{ marginLeft: 10 }}>Partners</h3>
      </div>

      <Row style={{ paddingTop: 10 }}>
        {SPONSORS.map((sponsor, idx) => {
          if (idx == 2 || idx == 3) {
            return (
              <Col
                key={idx}
                style={{
                  // backgroundColor: 'black',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bottom: 30,
                }}
              >
                <CardImageOverlay img={sponsor} />
              </Col>
            )
          }
          return (
            <Col
              key={idx}
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <CardImageOverlay img={sponsor} />
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}
